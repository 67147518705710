@import './tailwind.css';

.square {
	position: relative;
}

.square::after {
	content: '';
	display: block;
	padding-bottom: 100%;
}
